<template>

  <div class="d-flex flex-column">
    <label class="small-label" v-if="label">{{label}}</label>

    <select :disabled="disabled" class="input-small" v-model="inputVal">
      <option :key="`${uuid}_${k}`" v-for="(opt, k) in opts" v-bind:value="opt[prop_value]">{{ opt[prop_label] }}</option>
    </select>


  </div>

</template>

<script>

import { generateUUID } from "../../utils";

export default {
  props: ["value","opts",'prop_label','prop_value',"label","disabled"],
  data() {
    return {
      uuid: generateUUID()
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }


};
</script>

<style>

</style>
