
// initial state
const state = () => ({
})

// getters
const getters = {}


const actions = {
    log(context, opt) {
        const {level,msg} = opt
        this._vm.$toastr.defaultTimeout = 2000;
        this._vm.$toastr[level](msg);
    },
}

const mutations = {};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}