import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import util from "./util";
import products from "./modules/products";
import surveys from "./modules/surveys";
import expenses from "./modules/expenses";
import myroute from "./modules/myroute";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    util,
    products,
    surveys,
    expenses,
    myroute,
  }
});
