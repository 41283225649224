import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import { API_URL } from "../config";
import store from "../store";
import queryString from "query-string";
import { logError } from "../utils";

const API_URL = process.env.VUE_APP_API_URL + "/api";
const ASSETS_URL = process.env.VUE_APP_API_URL;

const ID_TOKEN_KEY = "id_token";

const withPromise = axiosInstance =>
  new Promise((resolve, reject) => {
    axiosInstance
      .then(res => resolve(res.data))
      .catch(({ response }) => {
        if (!response) {
          logError("Servicio no disponible");
          return reject("Service is unavailable");
        }
        logError(response.data);
        if (response.status === 403) {
          // permission is denied
          return store.dispatch("auth/onForbidden");
        } else if (response.status === 401) {
          return store.dispatch("auth/logout");
        }else if (response.status === 503) {
          return store.dispatch("auth/onMaintenance");
        }
        reject(response);
      });
  });

const withPromiseNotAlert = axiosInstance =>
  new Promise((resolve, reject) => {
    axiosInstance
      .then(res => resolve(res.data))
      .catch(({ response }) => {
        if (!response) {
          // logError("Servicio no disponible");
          return reject("Service is unavailable");
        }
        // logError(response.data);
        if (response.status === 403) {
          // permission is denied
          return store.dispatch("auth/onForbidden");
        } else if (response.status === 401) {
          return store.dispatch("auth/logout");
        }
        reject(response);
      });
  });

const ApiService = {
  initialize() {
    const token = ApiService.getToken();
    if (token) {
      Vue.axios.defaults.headers.common["Authorization"] = token;
    } else {
      delete Vue.axios.defaults.headers.common["Authorization"];
    }
  },

  stringifyUrl(url, obj) {
    const cleanObject = function(obj) {
      return Object.keys(obj).reduce((prev, k) => {
        if (obj[k] != null) {
          prev[k] = obj[k];
        }
        return prev;
      }, {});
    };

    const query = cleanObject(obj);
    return queryString.stringifyUrl({ url, query });
  },

  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },

  get(url, config) {
    ApiService.initialize();
    return withPromise(Vue.axios.get(url, config));
  },

  post(url, data, config) {
    ApiService.initialize();
    return withPromise(Vue.axios.post(url, data, config));
  },

  postNotAlert(url, data, config) {
    ApiService.initialize();
    return withPromiseNotAlert(Vue.axios.post(url, data, config));
  },

  put(url, data, config) {
    ApiService.initialize();
    return withPromise(Vue.axios.put(url, data, config));
  },

  delete(url, config) {
    ApiService.initialize();
    return withPromise(Vue.axios.delete(url, config));
  },

  //use this to remove while testing: localStorage.removeItem('id_token');
  saveToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  },

  destroyToken() {
    window.localStorage.removeItem(ID_TOKEN_KEY);
  },

  getToken() {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  },

  getImageOrFileGlobalUri(imgStatic) {
    if (imgStatic && imgStatic?.type === "file") {
      return this.getFileUri(imgStatic);
    }
    return this.getImageUri(imgStatic);
  },

  getThumbnailUri(image) {
    if (!image?.file) return null;
    return `${ASSETS_URL}/static/${encodeURIComponent(
      image?.unit
    )}/images/${encodeURIComponent(`thumbnails`)}/${image.file}`;
  },

  getImageUri(image) {
    if (!image?.file) return null;
    return `${ASSETS_URL}/static/${encodeURIComponent(
      image?.unit
    )}/images/${encodeURIComponent(`originals`)}/${image.file}`;
  },

  getFileUri(image) {
    if (!image?.file) return null;
    return `${ASSETS_URL}/static/${encodeURIComponent(
      image?.unit
    )}/files/${encodeURIComponent(`file`)}/${image.file}`;
  },


  async downloadAsset(asset) {
    if (!asset?.file) return;
    ApiService.initialize();

    const url = `${ASSETS_URL}/static/${encodeURIComponent(
        asset?.unit
    )}/files/${encodeURIComponent(`file`)}/${asset.file}`;

    const fileName = asset.originalFileName || asset.file
    const res = await Vue.axios.get(url, { responseType: "blob" });
    const fileURL = window.URL.createObjectURL(new Blob([res.data]));
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);

  },


  getExcelTemplateFileUri(file) {
    if (!file) return null;
    return `${ASSETS_URL}/importexceltemplates/${file}`;
  },

  getDownloadExcelTemplateUrl(excelCategory) {
    if (!excelCategory) return null;
    return `${API_URL}/downloadexceltemplate/${excelCategory}`;
  },

  getdownloadsigndoctemplateURL(id) {
    if (!id) return null;
    return `${API_URL}/expenses/liquidations/downloaddoctemplate/${id}`;
  },

  async download(url, filename, onProgress) {
    ApiService.initialize();
    const res = await Vue.axios.get(url, {
      responseType: "blob",
      onDownloadProgress: ev => {
        if (ev.type === "progress") {
          const { loaded, total } = ev;
          let progress = Math.floor((loaded / total) * 100);
          const totalMb = Math.floor(parseInt(total) / Math.pow(10, 6));
          if (onProgress) {
            onProgress({ total: totalMb, progress });
          }
        }
      }
    });
    const fileURL = window.URL.createObjectURL(new Blob([res.data]));
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", filename);
    document.body.appendChild(fileLink);
    fileLink.click();
  },

  _uploadPicture(url, file = {}, isNotAlert) {
    const formData = new FormData();
    formData.append("image", file);

    const config = {
      headers: {
        "content-type": `multipart/form-data`
      }
    };
    if (isNotAlert) {
      return withPromiseNotAlert(Vue.axios.post(url, formData, config));
    } else {
      return withPromise(Vue.axios.post(url, formData, config));
    }
  },

  uploadImage(file, isNotAlert) {
    let url = `${API_URL}/upload/images`;
    return ApiService._uploadPicture(url, file, isNotAlert);
  },

  base64toFile(base64String, filename, mimeType) {
    // // Remove the data:image/<mimeType>;base64, prefix
    // const base64WithoutPrefix = base64String.replace(
    //   /^data:image\/\w+;base64,/,
    //   ""
    // );

    // Convert the base64 string to a Uint8Array
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the Uint8Array
    const blob = new Blob([byteArray], { type: mimeType });

    // Create a File from the Blob
    return new File([blob], filename, { type: mimeType });
  },

  _uploadBase64Picture(url, base64Image, isNotAlert) {
    const base64ImageString = base64Image; // Your base64 string here
    const filename = "image.png"; // Set the desired filename
    const mimeType = "image/png"; // Set the correct MIME type

    const file = ApiService.base64toFile(base64ImageString, filename, mimeType);

    const formData = new FormData();
    formData.append("image", file);

    const config = {
      headers: {
        "content-type": `multipart/form-data`
      }
    };
    if (isNotAlert) {
      return withPromiseNotAlert(Vue.axios.post(url, formData, config));
    } else {
      return withPromise(Vue.axios.post(url, formData, config));
    }
  },

  uploadBase64Image(file, isNotAlert) {
    let url = `${API_URL}/upload/images`;
    return ApiService._uploadBase64Picture(url, file, isNotAlert);
  },

  _uploadFile(url, file = {}) {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "content-type": `multipart/form-data`
      }
    };
    return withPromise(Vue.axios.post(url, formData, config));
  },

  uploadFile(file) {
    let url = `${API_URL}/upload/files`;
    return ApiService._uploadFile(url, file);
  },

  async getAddress(latlng) {
    console.log(latlng);
    if (latlng) {
      const geocoder = new window.google.maps.Geocoder();
      try {
        const response = await geocoder.geocode({ location: latlng });
        console.log(response);
        if (response.results[0]) {
          return response.results[0];
        } else {
          window.alert("No results found");
          return null;
        }
      } catch (error) {
        window.alert("Geocoder failed due to: " + error);
        return null;
      }
    }
    return null;
  }
};

export default ApiService;
