const state = {
  results: {} //dict by brand/family/product
};

const getters = {

    getResults: (state) => (sessionId, posId, surveyId) => {
        const id = parseId(sessionId, posId, surveyId)
        return state.results[id]
    },

}

const parseId = (sessionId, posId, surveyId) => `${sessionId}_${posId}_${surveyId}`

const actions = {

    saveResults({ commit }, { sessionId, posId, surveyId, results }) {
        const id = parseId(sessionId, posId, surveyId)
        commit('set', {id,results})
    },
};

const mutations = {

    set(state, {id, results}) {
        state.results[id] = results;
    },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
