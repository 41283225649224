import ApiService from "@/services/apiService";

const state = {
    monthly_km_data: {},
    monthly_other_expenses_data: {},
    statistics_data_km: {},
    statistics_data1_km: {},
    statistics_data_other_expenses: {},
    statistics_data1_other_expenses: {},
    statistics_data_liquidation: {},
    // chart data in analysis page
    analysis_chart_data_km: {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
        yaxis: [
            {
                title: {
                    text: "2022",
                },
            },
            {
                opposite: true,
                title: {
                    text: "2021",
                },
            },
        ],
        dataSeries: [
            {
                name: "2022",
                type: "column",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
                name: "2021",
                type: "line",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
        ],
    },
    analysis_chart_data_oe: {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
        yaxis: [
            {
                title: {
                    text: "2022",
                },
            },
            {
                opposite: true,
                title: {
                    text: "2021",
                },
            },
        ],
        dataSeries: [
            {
                name: "2022",
                type: "column",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
                name: "2021",
                type: "line",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
        ],
    },
    analysis_chart_data_km_avg: {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
        yaxis: [
            {
                title: {
                    text: "2022",
                },
            },
            {
                opposite: true,
                title: {
                    text: "2021",
                },
            },
        ],
        dataSeries: [
            {
                name: "2022",
                type: "column",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
                name: "2021",
                type: "line",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
        ],
    },
    analysis_chart_data_oe_avg: {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
        yaxis: [
            {
                title: {
                    text: "2022",
                },
            },
            {
                opposite: true,
                title: {
                    text: "2021",
                },
            },
        ],
        dataSeries: [
            {
                name: "2022",
                type: "column",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
                name: "2021",
                type: "line",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
        ],
    },
    // top titles for statistics data in analysis page
    analysis_top_titles_km: {
        avg_km: {
            cur_month: 0,
            prev_month: 0,
            avg_rest: 0
        },
        total_km: {
            cur_month: 0,
            prev_month: 0,
            avg_rest: 0
        }
    },
    analysis_top_titles_oe: {
        total: {
            cur_month: 0,
            prev_month: 0,
            avg_rest: 0
        },
        food: {
            cur_month: 0,
            prev_month: 0,
            avg_rest: 0
        },
        transport: {
            cur_month: 0,
            prev_month: 0,
            avg_rest: 0
        },
        hotels: {
            cur_month: 0,
            prev_month: 0,
            avg_rest: 0
        },
        others: {
            cur_month: 0,
            prev_month: 0,
            avg_rest: 0
        },
    }
};

const getters = {
    analysis_chart_data_km_avg(state) {
        return state.analysis_chart_data_km_avg;
    },
    analysis_chart_data_oe_avg(state) {
        return state.analysis_chart_data_oe_avg;
    },
    monthly_km_data(state) {
        return state.monthly_km_data;
    },
    monthly_other_expenses_data(state) {
        return state.monthly_other_expenses_data;
    },
    statistics_data_km(state) {
        return state.statistics_data_km;
    },
    statistics_data1_km(state) {
        return state.statistics_data1_km;
    },
    statistics_data_other_expenses(state) {
        return state.statistics_data_other_expenses;
    },
    statistics_data1_other_expenses(state) {
        return state.statistics_data1_other_expenses;
    },
    statistics_data_liquidation(state) {
        return state.statistics_data_liquidation;
    },
    analysis_chart_data_km(state) {
        return state.analysis_chart_data_km;
    },
    analysis_chart_data_oe(state) {
        return state.analysis_chart_data_oe;
    },
    analysis_top_titles_km(state) {
        return state.analysis_top_titles_km;
    },
    analysis_top_titles_oe(state) {
        return state.analysis_top_titles_oe;
    },
}

const actions = {
    setAnalysisTopTitlesOE(context, criteria) {
        let currentdate = new Date();
        let get_criteria = {
            ...criteria,
            cur_year: currentdate.getFullYear(),
            cur_month: currentdate.getMonth() + 1,
        }
        return ApiService.post("expenses/analysis/get_toptitles_data_oe", get_criteria)
            .then((data) => {
                context.commit('setAnalysisTopTitlesOE', data);
            })
            .catch(() => {
                console.log('error');
            })
    },
    setAnalysisTopTitlesKM(context, criteria) {
        let currentdate = new Date();
        let get_criteria = {
            ...criteria,
            cur_year: currentdate.getFullYear(),
            cur_month: currentdate.getMonth() + 1,
        }
        return ApiService.post("expenses/analysis/get_toptitles_data_km", get_criteria)
            .then((data) => {
                context.commit('setAnalysisTopTitlesKM', data);
            })
            .catch(() => {
                console.log('error');
            })
    },
    setAnalysisChartDataKM(context, criteria) {
        let currentdate = new Date();
        let get_criteria = {
            ...criteria,
            cur_year: currentdate.getFullYear(),
            prev_year: currentdate.getFullYear() - 1,
        }
        return ApiService.post("expenses/analysis/get_chart_data_km", get_criteria)
            .then((data) => {
                context.commit('setAnalysisChartDataKM', data);
            })
            .catch(() => {
                console.log('error');
            })
    },
    setAnalysisChartDataKMAVG(context, criteria) {
        let currentdate = new Date();
        let get_criteria = {
            ...criteria,
            cur_year: currentdate.getFullYear(),
            prev_year: currentdate.getFullYear() - 1,
        }
        return ApiService.post("expenses/analysis/get_chart_data_km_avg", get_criteria)
            .then((data) => {
                context.commit('setAnalysisChartDataKMAVG', data);
            })
            .catch(() => {
                console.log('error');
            })
    },
    setAnalysisChartDataOEAVG(context, criteria) {
        let currentdate = new Date();
        let get_criteria = {
            ...criteria,
            cur_year: currentdate.getFullYear(),
            prev_year: currentdate.getFullYear() - 1,
        }
        return ApiService.post("expenses/analysis/get_chart_data_oe_avg", get_criteria)
            .then((data) => {
                context.commit('setAnalysisChartDataOEAVG', data);
            })
            .catch(() => {
                console.log('error');
            })
    },
    setAnalysisChartDataOE(context, criteria) {
        let currentdate = new Date();
        let get_criteria = {
            ...criteria,
            cur_year: currentdate.getFullYear(),
            prev_year: currentdate.getFullYear() - 1,
        }
        return ApiService.post("expenses/analysis/get_chart_data_oe", get_criteria)
            .then((data) => {
                context.commit('setAnalysisChartDataOE', data);
            })
            .catch(() => {
                console.log('error');
            })
    },
    setMonthlyKMData(context, { whole_totalKM, costPerKM }) {
        let km_data = {
            totalKM: whole_totalKM,
            costperKM: costPerKM,
        }
        context.commit('setMonthlyKMData', km_data);
    },
    setMonthlyOtherExpensesData(context, data) {
        context.commit('setMonthlyOtherExpensesData', data);
    },
    setStatisticsDataKM(context, data) {
        context.commit('setStatisticsDataKM', data);
    },
    setStatisticsData1KM(context, data) {
        context.commit('setStatisticsData1KM', data);
    },
    setStatisticsDataOtherExpenses(context, data) {
        context.commit('setStatisticsDataOtherExpenses', data);
    },
    setStatisticsData1OtherExpenses(context, data) {
        context.commit('setStatisticsData1OtherExpenses', data);
    },
    setStatisticsDataLiquidation(context, data) {
        context.commit('setStatisticsDataLiquidation', data);
    },
};

const mutations = {
    setAnalysisChartDataKMAVG(state, data) {
        state.analysis_chart_data_km_avg = data;
    },
    setAnalysisChartDataOEAVG(state, data) {
        state.analysis_chart_data_oe_avg = data;
    },
    setAnalysisTopTitlesOE(state, data) {
        state.analysis_top_titles_oe = data;
    },
    setAnalysisTopTitlesKM(state, data) {
        state.analysis_top_titles_km = data;
    },
    setAnalysisChartDataOE(state, data) {
        state.analysis_chart_data_oe = data;
    },
    setAnalysisChartDataKM(state, data) {
        state.analysis_chart_data_km = data;
    },
    setMonthlyKMData(state, data) {
        state.monthly_km_data = data;
    },
    setMonthlyOtherExpensesData(state, data) {
        state.monthly_other_expenses_data = data;
    },
    setStatisticsDataKM(state, data) {
        state.statistics_data_km = data;
    },
    setStatisticsData1KM(state, data) {
        state.statistics_data1_km = data;
    },
    setStatisticsDataOtherExpenses(state, data) {
        state.statistics_data_other_expenses = data;
    },
    setStatisticsData1OtherExpenses(state, data) {
        state.statistics_data1_other_expenses = data;
    },
    setStatisticsDataLiquidation(state, data) {
        state.statistics_data_liquidation = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
