import ApiService from "@/services/apiService";


const state = {
  products: {} //dict by brand/family/product
};

const getters = {

    products(state) {
        return state.products;
    },
}

const actions = {

    fetch(context, opt) {
        return ApiService.get("products").then(({data})=>context.commit('set', data))
    },
};

const mutations = {
    get(state) {
        return state.products;
    },
    set(state, data) {
        state.products = data;
    },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
