<template>
  <v-app>
    <nav-bar></nav-bar>
    <v-snackbar
      v-model="globalSnackbarState.isShowSnackbar"
      color="deep-purple"
      elevation="24"
      :timeout="-1"
      centered
    >
      <template v-for="(item, index) in globalSnackbarState.contents">
        <p class="globalsnackbarparagraph" :key="index">{{ item }}</p>
      </template>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="closeglobalSnackbarState">
          OK
        </v-btn>
      </template>
    </v-snackbar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import navBar from "./components/navbar";

export default {
  name: "App",
  components: {
    navBar
  },
  data: function() {
    return { snackbar: true, checkMemorySizeIntervalId: null };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "user", "globalSnackbarState"])
  },
  methods: {
    checkMemorySize() {
      // Check if the browser supports the Performance API
      if (process.env.VUE_APP_DISABLE_MEMORY_CHECK !== "1" && "performance" in window && "memory" in performance) {
        // Set a threshold for memory usage (in bytes)
        const memoryThreshold = 99; // Adjust this value based on your application's needs
        console.log("performance.memory - ", performance.memory);

        // Function to check memory usage and handle exceed events
        const checkMemoryUsage = () => {
          const {
            usedJSHeapSize,
            totalJSHeapSize,
            jsHeapSizeLimit
          } = performance.memory;

          // Calculate the percentage of used memory
          const usedMemoryPercentage = (usedJSHeapSize / totalJSHeapSize) * 100;

          // Check if the used memory exceeds the threshold
          if (usedMemoryPercentage > memoryThreshold) {
            // Log memory usage for monitoring purposes
            console.log(
              `MEMORY_CONSOLE - ${usedJSHeapSize}, ${totalJSHeapSize}, ${jsHeapSizeLimit}, ${usedMemoryPercentage.toFixed(
                2
              )}%`
            );
            // Handle memory exceed event here
            console.warn("Memory usage exceeds threshold!");
            // redirect offline page !
            this.$router.push({
              name: "router_memoryexceed_page"
            });
          }
        };

        // Set up a timer to periodically check memory usage
        const memoryCheckInterval = 2000; // Check every 5 seconds (adjust as needed)
        if (!this.checkMemorySizeIntervalId) {
          this.checkMemorySizeIntervalId = setInterval(
            checkMemoryUsage,
            memoryCheckInterval
          );
        }
      } else {
        console.warn(
          "Performance API not supported. Unable to monitor memory usage."
        );
      }
    },
    closeglobalSnackbarState() {
      this.$store.dispatch("auth/hideglobalsnackbar");
    }
  },
  mounted() {
    this.checkMemorySize();
  },
  destroyed() {
    if (this.checkMemorySizeIntervalId !== null) {
      clearInterval(this.checkMemorySizeIntervalId);
    }
  }
};
</script>
<style>
.text-sm {
  font-size: 13px;
}
table.table-dense tr,
table.table-dense th,
table.table-dense td {
  padding: 0;
}
</style>
