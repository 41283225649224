import Vue from "vue";
import moment from "moment";
import ApiService from "@/services/apiService";
import download from "js-file-download";
import * as XLSX from "xlsx";
import _ from "lodash";

Vue.mixin({
  data: function() {
    return {
      costperKM: 0.236,
      deductAmountPerdayKM: 18,
      gennera_user_status: {
        active: "activo",
        inactive: "inactivo",
      },
      monthList: [
        { id: 1, name: "ENE" },
        { id: 2, name: "FEB" },
        { id: 3, name: "MAR" },
        { id: 4, name: "ABR" },
        { id: 5, name: "MAY" },
        { id: 6, name: "JUN" },
        { id: 7, name: "JUL" },
        { id: 8, name: "AGO" },
        { id: 9, name: "SEPT" },
        { id: 10, name: "OCT" },
        { id: 11, name: "NOV" },
        { id: 12, name: "DIC" }
      ],
      importExcelTemplateFileNames: {
        ID_POS_BRAND: "pos_brand_ids.xlsx",
        BRAND_ASSORTMENT: "assortments.xlsx",
        PRODUCTS: "products.xlsx",
        POS_ADMIN: "pos_admin.xlsx",
        USERS: "users.xlsx",
        MATERIALS: "materials.xlsx",
        POS_CHANGE_GPS: "change_gps.xlsx",
        BRAND_POS_SALES_TYPES: "brand_pos_sales_types.xlsx"
      },
      vue2EditorOptionsWithEmojis: {
        modules: {
          toolbar: {
            container: [
              [{ size: ["small", false, "large"] }],
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
              ["link", "image", "video"],
              ["emoji"]
            ],
            handlers: {
              emoji: function() {}
            }
          },
          short_name_emoji: true,
          toolbar_emoji: true,
          textarea_emoji: false
        }
      }
    };
  },
  methods: {
    isNotEmptyMessageVisitAlert(message_visit_alert) {
      let ret = false;
      if (
        message_visit_alert?.message &&
        (message_visit_alert?.message?.gpv_message ||
          message_visit_alert?.message?.spv_message)
      ) {
        ret = true;
      }
      return ret;
    },
    getDateDifference(startDate, endDate) {
      // Convert both dates to timestamps
      const startTimestamp = startDate.getTime();
      const endTimestamp = endDate.getTime();

      // Calculate the difference in milliseconds
      const difference = endTimestamp - startTimestamp;

      // Calculate the difference in days, hours, minutes, and seconds
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      // Return the difference as an object
      return {
        days,
        hours,
        minutes,
        seconds
      };
    },

    getQueryParamsGlobal({
      pageIndex,
      pageSize,
      sorting,
      filterCriteria,
      filterableColumnsSelector
    }) {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + pageIndex);
      query_params.push("itemsPerPage=" + pageSize);
      if (sorting !== null) {
        for (const [key, value] of Object.entries(sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value
          });
        }
      }
      let filter_columns = document.querySelectorAll(filterableColumnsSelector);
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    numberFormatEs(val) {
      if (!isNaN(val)) {
        return Number(parseFloat(val).toFixed(2)).toLocaleString("es", {
          minimumFractionDigits: 2
        });
      }
      return "";
    },

    numberFormatEsCustomDecimalCount(val, decimalCount) {
      if (!isNaN(val)) {
        return Number(parseFloat(val).toFixed(decimalCount)).toLocaleString(
          "es",
          {
            minimumFractionDigits: decimalCount
          }
        );
      }
      return "";
    },

    currency_format(arg) {
      let val = (arg == null || isNaN(arg)) ? 0 : arg;
      const formatter = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR"
      });
      return formatter.format(val);
    },

    number_format_es(val) {
      const formatter = new Intl.NumberFormat("es-ES", {
        // style: "currency",
        // currency: "EUR",
      });
      return formatter.format(val);
    },

    getFirstAndLastDateOfMonth(year, month) {
      month = parseInt(month) >= 10 ? month : "0" + month;
      let lastDate = moment(year + "-" + month, "YYYY-MM").daysInMonth();
      let firstDay = year + "-" + month + "-01";
      let lastDay = year + "-" + month + "-" + lastDate;
      return {
        firstDate: firstDay,
        lastDate: lastDay
      };
    },

    workday_count: function(start, end) {
      var first = start.clone().endOf("week"); // end of first week
      var last = end.clone().startOf("week"); // start of last week
      var days = (last.diff(first, "days") * 5) / 7; // this will always multiply of 7
      var wfirst = first.day() - start.day(); // check first week
      if (start.day() == 0) --wfirst; // -1 if start with sunday
      var wlast = end.day() - last.day(); // check last week
      if (end.day() == 6) --wlast; // -1 if end with saturday
      return wfirst + Math.floor(days) + wlast; // get the total
    }, //              ^ EDIT: if days count less than 7 so no decimal point

    get_leavedays_requested: function(startDate, endDate, holidaypublics) {
      let public_holiday_count = holidaypublics ? holidaypublics.length : 0;
      holidaypublics.map(holiday_item => {
        let day = moment(holiday_item.publicholiday).day();
        if (!(day >= 1 && day <= 5)) {
          public_holiday_count--;
        }
        return holiday_item;
      });
      let value = {
        days: 0,
        weekend_count: 0,
        public_holiday_count,
        request_day: 0
      };
      if (!startDate || !endDate) {
        return value;
      }
      let date1 = new Date(startDate);
      let date2 = new Date(endDate);
      // To calculate the time difference of two dates
      let Difference_In_Time = date2.getTime() - date1.getTime();
      // To calculate the no. of days between two dates
      let days_count = Difference_In_Time / (1000 * 3600 * 24) + 1;
      let request_day = this.workday_count(moment(startDate), moment(endDate));

      value = {
        days: days_count,
        weekend_count: days_count - request_day,
        public_holiday_count,
        request_day: request_day - public_holiday_count
      };
      return value;
    },

    // get_holidays_requested: async function (startDate, endDate, holidaypublics, userId) {
    get_holidays_requested: async function(
      holidayItem,
      user,
      isNaturals_paid_day_limits
    ) {
      const { companyCode } = user;
      let selected_is_natural = false;
      if (
        holidayItem["holidayType"] &&
        holidayItem["holidayType"] !== "VACATION" &&
        holidayItem["paidType"]
      ) {
        if (companyCode && isNaturals_paid_day_limits) {
          selected_is_natural =
            isNaturals_paid_day_limits[holidayItem["paidType"]];
        } else {
          selected_is_natural =
            isNaturals_paid_day_limits[holidayItem["paidType"]];
        }
      } else {
        selected_is_natural = false;
      }
      console.log("sssssssss - ", selected_is_natural);
      if (holidayItem["holidayType"] !== "VACATION") {
        let value = {
          days: 0,
          weekend_count: 0,
          request_day: 0
        };
        if (!holidayItem.startDate || !holidayItem.endDate) {
          return value;
        }
        let date1 = new Date(holidayItem.startDate);
        let date2 = new Date(holidayItem.endDate);
        // To calculate the time difference of two dates
        let Difference_In_Time = date2.getTime() - date1.getTime();
        // To calculate the no. of days between two dates
        let days_count = Difference_In_Time / (1000 * 3600 * 24) + 1;
        value = {
          days: days_count,
          request_day: 0
        };
        return value;
      }
      if (selected_is_natural) {
        let value = {
          days: 0,
          weekend_count: 0,
          request_day: 0
        };
        if (!holidayItem.startDate || !holidayItem.endDate) {
          return value;
        }
        let date1 = new Date(holidayItem.startDate);
        let date2 = new Date(holidayItem.endDate);
        // To calculate the time difference of two dates
        let Difference_In_Time = date2.getTime() - date1.getTime();
        // To calculate the no. of days between two dates
        let days_count = Difference_In_Time / (1000 * 3600 * 24) + 1;
        value = {
          days: days_count,
          request_day: days_count
        };
        return value;
      } else {
        let public_holiday_count = holidayItem.holidaypublics
          ? holidayItem.holidaypublics.length
          : 0;
        holidayItem.holidaypublics.map(holiday_item => {
          let day = moment(holiday_item.publicholiday).day();
          if (!(day >= 1 && day <= 5)) {
            public_holiday_count--;
          }
          return holiday_item;
        });
        let value = {
          days: 0,
          weekend_count: 0,
          public_holiday_count,
          request_day: 0
        };
        if (!holidayItem.startDate || !holidayItem.endDate) {
          return value;
        }
        let date1 = new Date(holidayItem.startDate);
        let date2 = new Date(holidayItem.endDate);
        // To calculate the time difference of two dates
        let Difference_In_Time = date2.getTime() - date1.getTime();
        // To calculate the no. of days between two dates
        let days_count = Difference_In_Time / (1000 * 3600 * 24) + 1;
        let request_day = this.workday_count(
          moment(holidayItem.startDate),
          moment(holidayItem.endDate)
        );

        let leavedays_count_in_the_period = 0;
        if (
          holidayItem.startDate &&
          holidayItem.endDate &&
          holidayItem.userId
        ) {
          const resp = await ApiService.get(
            `holiday/get_leavedays_count_intheperiod/${holidayItem.startDate}/${holidayItem.endDate}/${holidayItem.userId}`
          );
          leavedays_count_in_the_period = resp.leavedays_count_in_the_period;
        }
        request_day =
          request_day - public_holiday_count - leavedays_count_in_the_period > 0
            ? request_day - public_holiday_count - leavedays_count_in_the_period
            : 0;
        value = {
          days: days_count,
          weekend_count: days_count - request_day,
          public_holiday_count,
          leavedays_count_in_the_period,
          request_day
        };
        return value;
      }
    },

    get_duration_format(milliseconds) {
      let b = Math.abs(milliseconds);
      var s = Math.floor((b / 1000) % 60);
      var m = Math.floor((b / 1000 / 60) % 60);
      var h = Math.floor((b / (1000 * 60 * 60)) % 24);
      let f = moment({ h, m, s }).format("HH:mm:ss");
      return f;
    },

    get_duration: function(startMoment, endMoment) {
      if (startMoment && endMoment) {
        let startTime = moment(startMoment);
        let endTime = moment(endMoment);
        var duration = moment.duration(endTime.diff(startTime));
        let f = this.get_duration_format(duration.asMilliseconds());
        return f;
      }
      return null;
    },

    get_duration_format_hhmm(milliseconds) {
      let b = Math.abs(milliseconds);
      var s = Math.floor((b / 1000) % 60);
      var m = Math.floor((b / 1000 / 60) % 60);
      var h = Math.floor((b / (1000 * 60 * 60)) % 24);
      let f = moment({ h, m, s }).format("HH:mm");
      return f;
    },

    get_duration_hhmm: function(startMoment, endMoment) {
      if (startMoment && endMoment) {
        let startTime = moment(startMoment);
        let endTime = moment(endMoment);
        var duration = moment.duration(endTime.diff(startTime));
        let f = this.get_duration_format_hhmm(duration.asMilliseconds());
        return f;
      }
      return null;
    },

    getSecFromDuration(duration) {
      if (duration) {
        let hours, mins, secs;
        [hours, mins, secs] = duration
          .split(":")
          .slice(-3)
          .map(n => parseInt(n, 10));
        return hours * 3600 + mins * 60 + secs;
      }
      return 0;
    },

    get_duration_with_criteria(tableData) {
      let totalSecWorked = 0;
      let totalSecPaused = 0;
      for (const item of tableData) {
        let duration = this.get_duration(item.startMoment, item.endMoment);
        if (duration !== null) {
          if (item.logType === "WORK") {
            totalSecWorked += this.getSecFromDuration(duration);
          } else if (item.logType === "PAUSE") {
            totalSecPaused += this.getSecFromDuration(duration);
          }
        } else {
          let current_duration = this.get_duration(
            moment(item.startMoment)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
            moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
          );
          if (item.logType === "WORK") {
            totalSecWorked += this.getSecFromDuration(current_duration);
          } else if (item.logType === "PAUSE") {
            totalSecPaused += this.getSecFromDuration(current_duration);
          }
        }
      }
      let workedTime = this.get_duration_format(totalSecWorked * 1000);
      let pausedTime = this.get_duration_format(totalSecPaused * 1000);
      ("HH:mm:ss");
      return { workedTime, pausedTime };
    },

    getFirstdayOfOneMonthsAgo(nowDate) {
      let minLimitDate = new Date(nowDate);
      minLimitDate.setMonth(minLimitDate.getMonth() - 1);
      return minLimitDate.toISOString().slice(0, 7) + "-01";
    },

    exportCSVfileGlobal(headers, rows) {
      let data = [];
      let header = {};
      let i = 0,
        j = 0;
      for (i = 0; i < headers.length; i++) {
        header[headers[i]] = headers[i];
      }
      console.log(header);
      for (i = 0; i < rows.length; i++) {
        let data_item = {};
        let current_key = "";
        for (j = 0; j < headers.length; j++) {
          current_key = headers[j];
          data_item[current_key] = rows[i][j];
        }
        data.push(data_item);
      }
      console.log(data);
      if (header) {
        data.unshift(header);
      }
      let jsonObject = JSON.stringify(data);
      let csv = this.convertToCSVGlobal(jsonObject);
      console.log(csv);
      let exportedFilenmae = "errors.csv";
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    convertToCSVGlobal(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },

    async downloadExcelWithXLSX(
      columns,
      tableData,
      xlsxFileName,
      xlsxSheetName
    ) {
      if (tableData.length > 0) {
        let excel_data = tableData;
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName = xlsxFileName ? xlsxFileName : "data";
        const sheetName = xlsxSheetName ? xlsxSheetName : "data";
        let headers = columns;
        let keyMap = {};
        let emptyRow = {};
        headers.forEach(column => {
          keyMap[column.key] = column.label;
          emptyRow[column.key] = "";
        });
        let content = [];
        excel_data.map(items => {
          let row = { ...emptyRow };
          for (const key of Object.keys(items)) {
            if (items[key] && keyMap[key]) {
              row[key] = items[key];
            }
          }
          content.push(row);
        });
        let Heading = [headers.map(el => el.label)];
        //Had to create a new workbook and then add the header
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, Heading);
        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, content, {
          origin: "A2",
          skipHeader: true
        });
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName + fileExtension);
      }
    },

    deepGet: _.get,

    sumBy: _.sumBy,

    async downloadExcelTemplateGlobal(downloadFile) {
      let downloadfile = ApiService.getExcelTemplateFileUri(downloadFile);
      try {
        const response = await ApiService.get(downloadfile, {
          responseType: "arraybuffer"
        });
        download(response, downloadFile);
      } catch (error) {
        // this.close();
      }
    },

    async downloadExcelTemplateAPI(excelCategory) {
      let fetchUrl = ApiService.getDownloadExcelTemplateUrl(excelCategory);
      let downloadFileName = "data.xlsx";
      if (
        Object.keys(this.importExcelTemplateFileNames).includes(excelCategory)
      ) {
        downloadFileName = this.importExcelTemplateFileNames[excelCategory];
      }
      try {
        const response = await ApiService.get(fetchUrl, {
          responseType: "arraybuffer"
        });
        download(response, downloadFileName);
      } catch (error) {
        console.log("error - ", error);
      }
    },

    formatNumberForPlus(value) {
      let str = value;
      if (value > 0) {
        str = `+ ${value}`;
      } else if (value < 0) {
        str = `- ${Math.abs(value)}`;
      }
      return str;
    },

    getIdPosBrandList(posItem) {
      let ret = [];
      if (posItem?.brand_list && posItem.brand_list.length > 0) {
        ret = this.getIdPosBrandListFromBrands(posItem?.brand_list);
      }
      return ret;
    },

    getIdPosBrandListFromBrands(brands) {
      let ret = [];
      if (brands.length > 0) {
        brands.map(bEl => {
          if (bEl?.pos && bEl?.pos.length > 0 && bEl?.pos[0]?.posbrands) {
            if (!_.isEmpty(bEl?.pos[0]?.posbrands.idPosBrand)) {
              ret.push(bEl?.pos[0]?.posbrands);
            }
          }
        });
      }
      return ret;
    },

    getPosChangeGeolocationManualData(data, userId) {
      let ret = null;
      if (data) {
        const { routeId, posId } = data;
        if (data?.po) {
          let pos_data = data.po;
          if (pos_data?.pos_change_geolocations) {
            let foundManualData = pos_data.pos_change_geolocations.find(
              el =>
                el.routeId == routeId &&
                el.posId == posId &&
                el.userId == userId
            );
            if (foundManualData) {
              ret = foundManualData;
            }
          }
        }
      }
      return ret;
    }
  }
});
