import ApiService from "@/services/apiService";
import i18n from "@/i18n";
import moment from "moment";

const state = {
  messageVisitAlert: {
    isShowSnackBar: false,
    message: null
  },
  isOffline: false,
  currentPosData: null,
  currentWorksessionData: null,
  worksessionPosBrands: null,
  currentInactiveData: null,
  currentRequestVisitDays: null,
  currentVisitDays: null,
  hasPermissionLocation: false,
  currentPosition: null,
  currentGmapAddress: null,
  currentSession: null,
  isEndedSession: true,
  localCurrentDate: moment(new Date())
    .local()
    .format("YYYY-MM-DD"),
  deviceInformation: null
};

const getters = {
  messageVisitAlert(state) {
    return state.messageVisitAlert;
  },
  isOffline(state) {
    return state.isOffline;
  },
  currentPosData(state) {
    return state.currentPosData;
  },
  currentWorksessionData(state) {
    return state.currentWorksessionData;
  },
  worksessionPosBrands(state) {
    return state.worksessionPosBrands;
  },
  currentInactiveData(state) {
    return state.currentInactiveData;
  },
  currentRequestVisitDays(state) {
    return state.currentRequestVisitDays;
  },
  currentVisitDays(state) {
    return state.currentVisitDays;
  },
  hasPermissionLocation(state) {
    return state.hasPermissionLocation;
  },
  currentPosition(state) {
    return state.currentPosition;
  },
  currentGmapAddress(state) {
    return state.currentGmapAddress;
  },
  currentSession(state) {
    return state.currentSession;
  },
  isEndedSession(state) {
    return state.isEndedSession;
  },
  localCurrentDate(state) {
    return state.localCurrentDate;
  },
  deviceInformation(state) {
    return state.deviceInformation;
  }
};

const actions = {
  async showMessageVisitAlert(context) {
    context.commit("showMessageVisitAlert");
  },
  async hideMessageVisitAlert(context) {
    context.commit("hideMessageVisitAlert");
  },
  async setMessageVisitAlert(context, messageVisitAlert) {
    context.commit("setMessageVisitAlert", messageVisitAlert);
  },
  async setIsOffline(context, isOffline) {
    context.commit("setIsOffline", isOffline);
  },
  async getCurrentPosData(context, criteria) {
    try {
      const { data } = await ApiService.get(
        `worksession/pos/${criteria.worksessionPosId}`
      );
      let posData = null;
      if (data?.po) {
        posData = data.po;
      }
      context.commit("setCurrentPosData", posData);
    } catch (error) {
      context.commit("setCurrentPosData", null);
    }
  },
  async getWorksessionPos(context, criteria) {
    try {
      const { data, worksessionPosBrands } = await ApiService.get(
        `worksession/pos/${criteria.worksessionPosId}`
      );
      context.commit("setCurrentWorksessionData", data);
      context.commit("setWorksessionPosBrands", worksessionPosBrands);
    } catch (error) {
      context.commit("setWorksessionPosBrands", null);
    }
  },
  async getCurrentInactiveData(context, criteria) {
    try {
      const { inactiveData } = await ApiService.get(
        `worksession/getCurrentInactiveData/${criteria.userId}/${criteria.routeId}/${criteria.posId}`
      );
      context.commit("setCurrentInactiveData", inactiveData);
    } catch (error) {
      context.commit("setCurrentInactiveData", null);
    }
  },
  async getCurrentRequestVisitDays(context, criteria) {
    try {
      const { requestVisitDays, currentVisitDays } = await ApiService.get(
        `worksession/getCurrentRequestVisitDays/${criteria.userId}/${criteria.routeId}/${criteria.posId}`
      );
      context.commit("setCurrentRequestVisitDays", requestVisitDays);
      context.commit("setCurrentVisitDays", currentVisitDays);
    } catch (error) {
      context.commit("setCurrentRequestVisitDays", null);
      context.commit("setCurrentVisitDays", null);
    }
  },
  async getCurrentPosition(context, callback) {
    try {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const { latitude, longitude, accuracy } = position.coords;
          const currentPosition = {
            latitude,
            longitude,
            accuracy
          };
          context.commit("setCurrentPosition", currentPosition);
          let latlng = {
            lat: currentPosition?.latitude,
            lng: currentPosition?.longitude
          };
          // const address = await ApiService.getAddress(latlng);
          // console.log("myroute-vuex-address - ", address);
          // let formatedAddress = null;
          // if (address && address?.formatted_address) {
          //   formatedAddress = address.formatted_address;
          // }
          // context.commit("setCurrentGmapAddress", formatedAddress);
          context.commit("seHasPermissionLocation", true);
          // if (callback) {
          //   callback(null, currentPosition, formatedAddress);
          // }
        },
        error => {
          console.log(error.message);
          context.commit("setCurrentPosition", null);
          if (callback) {
            callback(error);
          }
          detectPermissionForBrowserLocation(function(currentPermission) {
            // do something with map now that it is ready..
            if (currentPermission === "denied") {
              window.alert(i18n.t("message_permission_detect_alert"));
              context.commit("seHasPermissionLocation", false);
            } else if (currentPermission === "granted") {
              context.commit("seHasPermissionLocation", true);
            }
          });
        }
      );
    } catch (error) {
      context.commit("setCurrentPosition", null);
    }
  },
  async getCurrentSession(context, userId) {
    try {
      let LOCAL_DATE = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      let { data } = await ApiService.get(
        `worksession/user/${userId}/${LOCAL_DATE}`
      );
      context.commit("setCurrentSession", data);
      console.log("----------- current session init -----------", data);
    } catch (error) {
      context.commit("setCurrentSession", null);
    }
  },
  setWorkSession(context, sessionData) {
    let session = null;
    if (sessionData) session = sessionData;
    context.commit("setCurrentSession", session);
  },
  setLocalCurrentDate(context) {
    context.commit("setLocalCurrentDate");
  },
  setDeviceInformation(context) {
    const userAgent = navigator.userAgent;
    let deviceType;
    if (userAgent.match(/Mobile/i) || userAgent.match(/Android/i)) {
      deviceType = "MOBILE";
    } else if (userAgent.match(/Tablet/i) || userAgent.match(/iPad/i)) {
      deviceType = "TABLET";
    } else {
      deviceType = "PC";
    }

    let deviceMobileType = "Unknown";
    if (/Android/.test(userAgent)) {
      deviceMobileType = "ANDROID";
    } else if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
      deviceMobileType = "iOS";
    }

    const browserInfo = {};
    if (userAgent.indexOf("Chrome") !== -1) {
      browserInfo.name = "Chrome";
      browserInfo.version = userAgent.match(/Chrome\/(\d+\.\d+)/)[1];
    } else if (userAgent.indexOf("Firefox") !== -1) {
      browserInfo.name = "Firefox";
      browserInfo.version = userAgent.match(/Firefox\/(\d+\.\d+)/)[1];
    } else if (userAgent.indexOf("Edge") !== -1) {
      browserInfo.name = "Edge";
      browserInfo.version = userAgent.match(/Edge\/(\d+\.\d+)/)[1];
    } else if (userAgent.indexOf("MSIE") !== -1) {
      browserInfo.name = "Internet Explorer";
      browserInfo.version = userAgent.match(/MSIE (\d+\.\d+)/)[1];
    } else {
      browserInfo.name = "Unknown";
      browserInfo.version = "N/A";
    }
    let deviceData = {
      type: deviceType,
      browserName: browserInfo.name,
      browserVersion: browserInfo.version,
      deviceMobileType
    };
    console.log("deviceInformation - ", deviceData);
    context.commit("setDeviceInformation", deviceData);
  }
};

function detectPermissionForBrowserLocation(callback) {
  if (!navigator.geolocation) return;
  navigator.permissions.query({ name: "geolocation" }).then(function(result) {
    console.log("result.state - ", result.state);
    callback(result.state);
    result.onchange = function() {
      callback(result.state);
    };
  });
}

const mutations = {
  showMessageVisitAlert(state) {
    state.messageVisitAlert = {
      ...state.messageVisitAlert,
      isShowSnackBar: true
    };
  },
  hideMessageVisitAlert(state) {
    state.messageVisitAlert = {
      ...state.messageVisitAlert,
      isShowSnackBar: false
    };
  },
  setMessageVisitAlert(state, data) {
    state.messageVisitAlert = data;
  },
  setIsOffline(state, data) {
    state.isOffline = data;
  },
  setCurrentPosData(state, data) {
    state.currentPosData = data;
  },
  setCurrentWorksessionData(state, data) {
    state.currentWorksessionData = data;
  },
  setWorksessionPosBrands(state, data) {
    state.worksessionPosBrands = data;
  },
  setCurrentInactiveData(state, data) {
    state.currentInactiveData = data;
  },
  setCurrentRequestVisitDays(state, data) {
    state.currentRequestVisitDays = data;
  },
  setCurrentVisitDays(state, data) {
    state.currentVisitDays = data;
  },
  seHasPermissionLocation(state, data) {
    state.hasPermissionLocation = data;
  },
  setCurrentPosition(state, data) {
    state.currentPosition = data;
  },
  setCurrentGmapAddress(state, data) {
    state.currentGmapAddress = data;
  },
  setCurrentSession(state, data) {
    state.currentSession = data;

    let isEnded = true;
    if (state.currentSession) {
      isEnded = state.currentSession.isEnded;
    }
    state.isEndedSession = isEnded;
  },
  setLocalCurrentDate(state) {
    state.localCurrentDate = moment(new Date())
      .local()
      .format("YYYY-MM-DD");
  },
  setDeviceInformation(state, data) {
    state.deviceInformation = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
