<template>

  <div class="d-flex flex-column">
    <label class="small-label" v-if="label">{{label}}</label>
    <input
            class="input-small"
            v-model="inputVal"
            type="text"
    />
  </div>

</template>

<script>

export default {
  props: ["value","label"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }


};
</script>

<style>

</style>
