<template>
  <div>
    <v-text-field
      hide-details
      v-model="searchString"
      v-bind:placeholder="$t('start_typing_to_search')"
      @input="searchItems"
    ></v-text-field>
    <div class="loading-spinner text-center mt-2 mb-2" v-if="isDataLoading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div class="list_options" v-if="filteredList.length > 0">
      <template v-for="item in filteredList">
        <v-checkbox
          v-model="filterCriteria[item.id]"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        ></v-checkbox>
      </template>
    </div>
    <div v-else class="text-center">{{ $t("Empty data") }}</div>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";

export default {
  name: "filterableAjaxCheckboxes",
  props: [
    "value",
    "apiUrl",
    "columnName",
    "isFullText",
    "criteria",
    "isSearch"
  ],
  data: function() {
    return {
      isDataLoading: false,
      searchString: "",
      filteredList: [],
      fakeData: [
        { id: 1, title: "153548" },
        { id: 2, title: "7834684" },
        { id: 3, title: "3s1f7834684" },
        { id: 4, title: "87ew7834684" },
        { id: 5, title: "1357834684" }
      ]
    };
  },
  watch: {
    filterCriteria(newVal, oldVal) {
      if (
        this.columnName === "brand_name" ||
        this.columnName === "survey_name" ||
        this.columnName === "portal_photo_name"
      ) {
        this.setSearchString("");
      }
      if (Object.keys(newVal).length === 0) {
        this.setSearchString("");
      }
    },
    isSearch: function(newVal, oldVal) {
      if (this.columnName === "portal_photo_name" && newVal) {
        this.setSearchString("");
      }
    }
  },
  computed: {
    filterCriteria: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    setSearchString(str) {
      this.searchString = str;
      this.getDataFromApi(this.searchString);
    },
    getFetchUrl(searchStr) {
      let fetch_url = this.apiUrl;
      let criteria = ["column=" + this.columnName, "filterValue=" + searchStr];
      if (this.isFullText) {
        criteria.push("isFullText=true");
      }
      if (this.criteria && this.criteria?.brand_name) {
        criteria.push(
          "idBrands=" +
            Object.values(this.criteria.brand_name)
              .filter(el => el)
              .join(",")
        );
      }
      if (this.criteria && this.criteria?.survey_name) {
        criteria.push(
          "idSurveys=" +
            Object.values(this.criteria.survey_name)
              .filter(el => el)
              .join(",")
        );
      }
      fetch_url += "?" + criteria.join("&");
      return fetch_url;
    },
    async searchItems() {
      this.getDataFromApi(this.searchString);
    },
    async getDataFromApi(searchString) {
      let fetch_url = this.getFetchUrl(searchString);
      this.isDataLoading = true;
      try {
        let response = await ApiService.get(fetch_url);
        if (response.data) {
          this.filteredList = response.data;
        }
        // this.filteredList = this.fakeData;
        this.isDataLoading = false;
      } catch (error) {
        logError(error);
        this.isDataLoading = false;
      }
    },
    init() {
      this.getDataFromApi("");
    }
  },
  async updated() {},
  async mounted() {
    this.init();
  }
};
</script>

<style>
.list_options {
  max-height: 300px;
  overflow-y: scroll;
}
.list_options .v-input--selection-controls {
  margin-top: 5px;
}
</style>
