<template>
  <div>
    <v-text-field
      hide-details
      v-model="searchString"
      placeholder="Search ..."
      @input="searchItem"
    ></v-text-field>
    <div class="list_options" v-if="filteredList.length > 0">
      <template v-for="item in filteredList">
        <v-checkbox
          v-model="filterCriteria[item[itemvalue]]"
          :key="item[itemvalue]"
          :label="item[itemlabel]"
          :value="item[itemvalue]"
        ></v-checkbox>
      </template>
    </div>
    <div v-else class="text-center">Empty</div>
  </div>
</template>

<script>
export default {
  name: "filterableCheckboxesOthers",
  props: ["value", "dataList", "itemlabel", "itemvalue"],
  data: function() {
    return {
      searchString: "",
      initialList: [],
      filteredList: [],
    };
  },
  watch: {
    filterCriteria(newVal, oldVal) {
      if (Object.keys(newVal).length === 0) {
        this.setSearchString("");
      }
    },
    async dataList(newVal, oldVal) {
      this.initialList = newVal;
      if (oldVal.length === 0) {
        this.filteredList = newVal;
      }
    },
  },
  computed: {
    filterCriteria: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    setSearchString(str) {
      this.searchString = str;
      this.filteredList = this.initialList;
    },
    searchItem() {
      if (this.searchString) {
        this.filteredList = this.initialList.filter((item) =>
          item[this.itemlabel]
            .toLowerCase()
            .includes(this.searchString.toLowerCase())
        );
      } else {
        this.filteredList = this.initialList;
      }
    },
    init() {
      this.initialList = this.dataList;
      this.filteredList = this.dataList;
    },
  },
  async updated() {},
  async mounted() {
    this.init();
  },
};
</script>

<style>
.list_options {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
