import Vue from "vue";
import CountryFlag from "vue-country-flag";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

// Importing the global css files
import "@/assets/multiselect.css";
import "@/assets/bootstrap.css";

// Importing vue-easytable
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library

import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";

import "@/assets/global.css";

//local components
import TextInput from "./components/inputs/Text";
import NumberInput from "./components/inputs/Number";
import CsvReader from "./components/inputs/CsvReader";
import SelectInput from "./components/inputs/Select";
import CheckboxInput from "./components/inputs/Checkbox";
import filterableCheckboxes from "./components/filterableCheckbox/filterableCheckboxes";
import filterableCheckboxesOthers from "./components/filterableCheckbox/filterableCheckboxesOthers";
import filterableAjaxCheckboxes from "./components/filterableCheckbox/filterableAjaxCheckboxes";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
// import GanttElastic from "gantt-elastic";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import ApiService from "./services/apiService";
import VueToastr from "vue-toastr";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "./plugins/vuetify-money.js";
import mixin from "./plugins/common";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueToastr, {});
Vue.use(VueEasytable);
Vue.use(filterableCheckboxes);
Vue.use(filterableCheckboxesOthers);
Vue.use(filterableAjaxCheckboxes);
Vue.use(VModal);
Vue.use(CalendarView);
Vue.use(CalendarViewHeader);
// Vue.use(GanttElastic);

//local components
Vue.component("SelectInput", SelectInput);
Vue.component("CheckboxInput", CheckboxInput);
Vue.component("TextInput", TextInput);
Vue.component("NumberInput", NumberInput);
Vue.component("CsvReader", CsvReader);
Vue.component("country-flag", CountryFlag);
Vue.component("filterableCheckboxes", filterableCheckboxes);
Vue.component("filterableCheckboxesOthers", filterableCheckboxesOthers);
Vue.component("filterableAjaxCheckboxes", filterableAjaxCheckboxes);

ApiService.init();

// // initial fetches
// store.dispatch('products/fetch')

if (window.location.hostname === "gennera-platform.com") {
  window.location.replace("https://gpv-platform.com/");
}

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify,
  i18n,
  mounted() {}
}).$mount("#app");
