import { parse, stringify } from "csv";
import _ from 'lodash'

export const parseCSV = function (content, columns, cb) {
    const reader = new FileReader();
    reader.addEventListener('error', (err) => cb('error al leer archivo ', err));

    reader.onloadend = function (evt) {
        const text = evt.target.result;
        return parse(text, {columns: true, delimiter: ';', trim: true}, (err, parsed) => {
            if (err) return cb('error al extraer datos ', err)
            if (parsed && parsed.length && columns && columns.length) {
                for (const col of columns) {
                    if (!(col in parsed[0])) {
                        return cb(`columna ${col} no encontrada `,null)
                    }
                }
                parsed = parsed.map(el=> _.pick(el,columns))
            }
            return cb(null, parsed)
        });
    };
    reader.readAsText(content);
}

export const downloadCSVFile = (filename, data, cb) => {

    return stringify(data, {
        header: true
    },function (err, res) {
        if(err) return cb(err)
        const blob = new Blob([res], {type: 'text/csv'});
        if(window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else{
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
        return cb(null)
    })


}
