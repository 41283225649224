<template>

    <fieldset class="m-0">
        <legend>
            <b-badge class="">importar csv</b-badge>
        </legend>


        <template v-if="cols && cols.length">

            <div class="d-flex mb-1" style="font-size: 11px">
                <b class="mr-1">cols:</b>
                <div class="column px-1" :key="`${uuid}_${k}`" v-for="(col,k) in cols">
                    {{col}}
                </div>
            </div>

        </template>

        <div class="d-flex flex-column">
            <input type='file' style="" @change="readFile" :ref="uuid">

            <span style="color: green; font-size: 11px" v-if="values"> filas cargadas: {{values.length}} </span>

            <span style="color: red; font-size: 11px" v-if="error"> {{error}} </span>

        </div>

    </fieldset>

</template>

<script>

    import {generateUUID, logError} from "../../utils";
    import {parseCSV} from "../../csv";

    export default {
        props: ["cols"],
        data() {
            return {
                uuid: generateUUID(),
                values: null,
                error: null
            }
        },
        methods: {

            logError(msg, err) {
                console.error(err)
                this.error = msg
                logError(msg)
            },
            readFile() {
                const self = this
                const content = this.$refs[this.uuid].files[0];

                return parseCSV(content, self.cols, (err, res)=> {
                    if(err) return self.logError(err)
                    self.values = res
                    self.error = null
                    self.$emit('change', res);
                })

            }
        }
    };
</script>

<style scoped>
    .column {
        border: 1px solid;
    }

</style>
